<template>
  <div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="v-title">
          Mütəxəssislər
        </div>
      </div>
      <div class="col-auto">
        <router-link :to="{ name: 'employees' }" class="v-link">Hamısına bax</router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col" v-if="!loading">
        <!-- <carousel-3d :perspective="0" space="400" controlsVisible :count="categories.length" display="3" border="0" disable3d  height="auto" :startIndex="1" :autoplay="false" :onMainSlideClick="handleSlideClick"> -->
        <carousel-3d :count="users.total" height="auto" :perspective="0" space="400" border="0" controlsVisible>
          <slide :index="index" v-for="(user, index) in users.data" :key="index" style="width: 18rem; cursor: pointer;">
            <div class="card" @click="handleSlideClick(user)">
              <img :src="user.photoPath ? user.photoPath : defaultUserImg" class="card-img-top"
                alt="design service image" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title">{{ user.fullname }}</h5>
                <p class="card-text v-employee-card-text-profession">{{ serviceName(user) }}</p>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Carousel3d, Slide } from 'vue-carousel-3d';
import defaultUserImg from '@/assets/default-avatar.jpg'
export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      defaultUserImg
    }
  },
  created() {
    this.$store.dispatch('user/searchUsers', {})
      .catch(err => {})
  },
  computed: {
    ...mapState({
      loading: state => state.user.loading,
      users: state => state.user.users
    })
  },
  methods: {
    serviceName(user) {
      const userServices = this.$store.getters['user/getUserServices'](user)

      return userServices?.map(item => item.name).join(", ")
    },
    handleSlideClick(user) {
      this.$router.push({ name: 'employeeDetails', params: { id: user.id } })
    }
  }
}
</script>

<style scoped>
.card-img-top {
  border-radius: 1rem;
}

.card-body {
  padding-left: 0;
}
.v-employee-card-text-profession {
  color: var(--Gray-Dark, #7B7B7B);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.v-title {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  /* 128.571% */
  margin-bottom: .75rem;
}

.v-link {
  color: var(--Primary-Pink-700, #ED1969);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}

.card {
  border: none;
}

.card-title {
  overflow: hidden;
  color: #282828;
  text-overflow: ellipsis;

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  /* 136.364% */
}

.card-text {
  color: #797979;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}
</style>