<template>
  <div id="categories">
    <div class="row justify-content-between">
      <div class="col">
        <div class="v-title mb-0">
          Xidmətlər
        </div>
      </div>
      <div class="col-auto">
        <router-link :to="{name: 'categories'}" class="v-link">Hamısına bax</router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col" v-if="!loading">
        <!-- <carousel-3d :perspective="0" space="400" controlsVisible :count="categories.length" display="3" border="0" disable3d  height="auto" :startIndex="1" :autoplay="false" :onMainSlideClick="handleSlideClick"> -->
        <carousel-3d :count="categories.length" height="auto" :perspective="0" space="400" border="0" controlsVisible :loop="false">
          <slide :index="index" v-for="(category, index) in categories" :key="index" style="width: 18rem; cursor: pointer;">
            <div class="card" @click="handleSlideClick(category)">
              <img :src="category.imagePath" class="card-img-top" alt="design service image" style="width: 18rem;">
              <div class="card-body">
                <h5 class="card-title">{{ category.name }}</h5>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { mapState } from 'vuex';
export default {
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
    }
  },
  methods: {
    handleSlideClick(category) {
      // console.log(category)
      this.$router.push({name: 'subCategories', params: {categoryId: category.id}})
        .catch(err => {})
    }
  },
  computed: {
    ...mapState({
      categories: state => state.category.categories,
      loading: state => state.category.loading
    }),
    categories1() {
      // return this.categories.filter(category => category.id !== '1951292f-2706-4f36-9c56-90980f4deed4')
      return this.categories.slice(0, 1)
    }
  }
}
</script>

<style scoped>
.card-body {
  padding-left: 0;
}
.v-title {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  /* 128.571% */
  margin-bottom: .75rem;
}

.v-link {
  color: var(--Primary-Pink-700, #ED1969);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}

.card {
  border: none;
}

.card-title {
  overflow: hidden;
  color: #282828;
  text-overflow: ellipsis;

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  /* 136.364% */
}

.card-text {
  color: #797979;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}
</style>

<style>
#categories .carousel-3d-container {
  overflow: visible;
}
#categories .carousel-3d-controls a.prev {
  left: -2rem;
  color: var(--Primary-Pink-700, #ED1969);
}

#categories .carousel-3d-controls a.next {
  right: -2rem;
  color: var(--Primary-Pink-700, #ED1969);
}
</style>

<style>
.el-carousel__item.el-carousel__item--card {
  width: auto;
  height: auto;
}

.carousel-3d-slider {
  height: 400px !important;
}
</style>