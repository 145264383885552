<!-- <template>
  <div>
    <carousel-3d :count="4" height="auto" :perspective="0" space="400" border="0" controlsVisible ref="carousel" display="3">
      <slide :index="0" :key="0" >
        <div class="card">
          <img src="@/assets/ad-1.jpeg" class="card-img-top" alt="design service image" >
        </div>
      </slide>
      <slide :index="1" :key="1">
        <div class="card">
          <img src="@/assets/ad-2.jpeg" class="card-img-top" alt="design service image" >
        </div>
      </slide>
      <slide :index="2" :key="2">
        <div class="card">
          <img src="@/assets/ad-3.jpeg" class="card-img-top" alt="design service image">
        </div>
      </slide>
      <slide :index="3" :key="3">
        <div class="card">
          <img src="@/assets/ad-4.jpeg" class="card-img-top" alt="design service image" >
        </div>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  components: {
    Carousel3d,
    Slide
  },
  computed: {
    refTest() {
      return this.$refs.carousel
    }
  }
}
</script>

<style scoped>
.card-img-top {
  border-radius: 1rem;
  width: 18rem;
}

.card-body {
  padding-left: 0;
}
.v-employee-card-text-profession {
  color: var(--Gray-Dark, #7B7B7B);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.v-title {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  /* 128.571% */
  margin-bottom: .75rem;
}

.v-link {
  color: var(--Primary-Pink-700, #ED1969);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}

.card {
  border: none;
  width: 388px;
}

.card-title {
  overflow: hidden;
  color: #282828;
  text-overflow: ellipsis;

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
  /* 136.364% */
}

.card-text {
  color: #797979;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}
</style> -->


<template>
  <div class="container">
    <hooper :itemsToShow="itemsToShow" :infiniteScroll="true" >
      <slide>  
        <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank">
          <div class="img-container-1"></div>
        </a>
      </slide>
      <slide>
        <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank">
          <div class="img-container-2"></div>
        </a>
      </slide>
      <slide>
        <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank">
          <div class="img-container-3"></div>
        </a>
      </slide>
      <slide>
        <a href="https://www.instagram.com/beh_beh_by_shahla?igsh=MTA3ZTc0OHc3bTU4Mg==" target="_blank">
          <div class="img-container-4"></div>
        </a>
      </slide>
      <HooperNavigation slot="hooper-addons"></HooperNavigation>
      <HooperPagination slot="hooper-addons"></HooperPagination>
    </hooper>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  mounted() {
    console.log(this.$refs.slide)
  }, 
  methods: {
    testing() {
      console.log('testing')
    }
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
  },
  computed: {
    itemsToShow() {
      if (window.outerWidth < 500) {
        return 1
      }
      else if (window.outerWidth <= 768) {
        return 2
      }
      else {
        return 3
      }
    }
  }
}
</script>
<style>
.hooper-slide {
  /* margin: 0 10px;
   */
  border: 5px solid transparent;
}

.hooper-prev svg.icon,
.hooper-next svg.icon {
  fill: #ed1969;
  width: 50px;
  height: 50px;
}
</style>

<style scoped>
.img-container-1 {
  background-image: url('@/assets/ad-1.jpeg');
  background-size: cover;
  /* or 'contain' */
  background-position: center;
  width: auto;
  /* or specific width */
  height: 200px;
  /* or specific height */
}

.img-container-2 {
  background-image: url('@/assets/ad-2-crop.jpeg');
  background-size: cover;
  /* or 'contain' */
  background-position: center;
  width: auto;
  /* or specific width */
  height: 200px;
  /* or specific height */
}

.img-container-3 {
  background-image: url('@/assets/ad-3-crop.jpeg');
  background-size: cover;
  /* or 'contain' */
  background-position: center;
  width: auto;
  /* or specific width */
  height: 200px;
  /* or specific height */
}

.img-container-4 {
  background-image: url('@/assets/ad-4-crop.jpeg');
  background-size: cover;
  /* or 'contain' */
  background-position: center;
  width: auto;
  /* or specific width */
  height: 200px;
  /* or specific height */
}

/* a {
  display: block;
  width: 100%;
  height: 100%;
} */
</style>