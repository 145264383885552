import axios from '@/axios'
import createAxiosInstance from '@/defaultAxios'

const state = {
  users: {},
  createdUserData: null,
  pagination: {
    usersPerPage: null,
    currentPage: 1,
    pages: null
  },
  loading: false
}

const mutations = {
  setLoading(state, loading) {
    state.loading = loading
  },
  setCreatedUserData(state, userDetails) {
    state.createdUserData = userDetails
  },
  setUsers(state, users) {
    state.users = users
  },
  setPagination(state, {currentPage, usersPerPage, pages}) {
    if (currentPage) state.pagination.currentPage = currentPage
    if (usersPerPage) state.pagination.usersPerPage = usersPerPage
    if (pages) state.pagination.pages = pages
  }
}

const getters = {
  getUserServices: (state, getters, rootState) => (user) => {
    if (user?.services.length) {
      const userCategory = rootState.category.categories.find(category => category.id === user.services[0].categoryId)
      if (userCategory) {
        const services = []
        for (const child of userCategory.children) {
          if (user.services[0].subCategoryIds.includes(child.id)) {
            services.push(child)
          }
        }
        return services
      }
      else return []
    }
  }
}

const actions = {
  uploadImage({commit}, {url, photo}) {
    const axiosInstance = createAxiosInstance()
    const headers = {
      'Content-Type': photo.type
    }

    return new Promise((resolve, reject) => {
      axiosInstance.put(url, photo, {headers})
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateSocialNetworkUsernames({commit}, {instagram, facebook, linkedin}) {
    return new Promise((resolve, reject) => {
      axios.put('/user/additional-info', {instagram, facebook, linkedin})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })

    })  
  },
  isPhoneNumberExist({commit}, phoneNumber) {
    return new Promise((resolve, reject) => {
      axios.post('/user/check/phone', {phone: phoneNumber})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  isEmailExists({commit}, email) {
    return new Promise((resolve, reject) => {
      axios.post('/user/check/email', {email})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })

    })    
  },
  createUser({commit}, {phone, fullname, birthdate, instagram, facebook, linkedin, languages, password, photo, email}) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/user', 
        {phone, fullname, birthdate, instagram, facebook, linkedin, languages, password, photo, email},
        // {headers: {'Content-Type': 'multipart/form-data'}}
      )
        .then(res => {
          commit('setCreatedUserData', res.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      
    })
  },
  updateUser({commit}, {phone, fullname, birthdate, instagram, facebook, linkedin, languages, photo, email}) {
    return new Promise((resolve, reject) => {
      axios.put(
        '/user', 
        {phone, fullname, birthdate, instagram, facebook, linkedin, languages, photo, email}
      )
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createUserService({commit}, {categoryId, subCategoryIds, address, price, workSchedule, additionalInfo, internship}) {
    return new Promise((resolve, reject) => {
      axios.post('/user/service', {categoryId, subCategoryIds, address, price, workSchedule, additionalInfo, internship})
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })

    })
  },
  updateUserService({commit}, {categoryId, subCategoryIds, address, price, workSchedule, additionalInfo, serviceId, internship}) {
    return new Promise((resolve, reject) => {
      axios.put(`/user/service/${serviceId}`, {categoryId, subCategoryIds, address, price, workSchedule, additionalInfo, internship})
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changePassword({commit}, {password, retryPassword}) {
    return new Promise((resolve, reject) => {
      axios.put(`/user/change/password`, {password, retryPassword})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUser({commit}, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/user/${userId}`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  searchUsers({commit}, {fullname, categoryId, subCategoryIds, page, perPage}) {
    commit('setLoading', true)
    
    const params = {
      fullname,
      categoryId, 
      subCategoryIds,
      page,
      perPage
    }

    return new Promise((resolve, reject) => {
      axios.get('/user/search', {params: params})
      
      .then(res => {
        setTimeout(() => {
          commit('setLoading', false)
        }, 1000);
        commit('setUsers', res.data)
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
      .finally(() => {
        commit('setLoading', false)
      })
    })
    
  },
  testing({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/userForm`, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}