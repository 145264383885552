<template>
  <nav class="navbar navbar-expand-md navbar-light mt-2 mt-xxl-5" style="background-color: transparent;"
    id="navbar">
    <div class="container d-md-flex align-items-baseline">
      <router-link :to="{ name: 'home' }" class="navbar-brand">
        <img src="@/assets/why-logo-label.svg" alt="" width="143">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="navbarSupportedContent">
        <div class="d-flex align-items-baseline flex-column flex-md-row w-100 mt-5 mt-md-0">
          <div class="mx-auto">
            <div class=" position-relative search-bar">
              <input v-model="fullName" @keypress.enter="searchUsersByName()" ref="searchInput" placeholder="Xidmət/Mütəxəssis adı..."/>
              <img src="@/assets/magnifying-glass.svg" class="position-absolute" @click="searchUsersByName()"
                style="cursor: pointer;">
            </div>
            <ul class="navbar-nav mb-2 mb-lg-0 mt-3 mt-sm-0 text-center text-sm-left justify-content-center">
              <li class="nav-item py-3 px-lg-3">
                <router-link :class="['nav-link', urlName === 'home' ? 'active' : null]" :to="{ name: 'home' }">Ana
                  səhifə</router-link>
              </li>
              <li class="nav-item py-3 px-lg-3">
                <!-- <a :class="['nav-link', urlName === 'categories' ? 'active' : null]" href="#categories"
                  v-if="urlName === 'home'">Xidmətlər</a> -->
                <router-link :class="['nav-link', urlName === 'categories' ? 'active' : null]" to="#categories"
                v-if="urlName === 'home'">Xidmətlər</router-link>
                <router-link :class="['nav-link', urlName === 'categories' ? 'active' : null]" :to="{ name: 'categories' }"
                  v-else>Xidmətlər</router-link>
              </li>
              <li class="nav-item py-3 px-lg-3">
                <router-link :class="['nav-link', urlName === 'employees' ? 'active' : null]"
                  :to="{ name: 'employees', query: { page: 1 } }">Mütəxəssislər</router-link>
              </li>
            </ul>
          </div>
          <ul class="navbar-nav mb-2 mb-lg-0 mx-auto mx-md-0">
            <!-- <li class="nav-item py-3 px-lg-3 search-icon">
              <router-link :to="{name: 'favorites'}" v-if="urlName === 'favorites'">
                <img src="@/assets/heart-active.svg" style="cursor: pointer;">
              </router-link>
              <router-link :to="{name: 'favorites'}" v-else>
                <img src="@/assets/favorite-icon.svg" style="cursor: pointer;">
              </router-link>
            </li> -->
            <li class="nav-item py-3 px-lg-3  mx-auto mx-sm-0">
              <el-dropdown trigger="click" @command="routeTo">
                <span class="el-dropdown-link">
                  <img src="@/assets/profile-icon-active.svg" v-if="urlName === 'login' || urlName === 'profile'"
                    class="el-dropdown-link">
                  <img src="@/assets/profile-icon.svg" v-else class="el-dropdown-link">
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="accessToken" @click="routeTo('profile')" command="profile">
                    Profil
                  </el-dropdown-item>
                  <el-dropdown-item v-else @click="routeTo('login')" command="login">
                    Daxil ol
                  </el-dropdown-item>
                  <el-dropdown-item @click="routeTo('createAccount')" command="createAccount">
                    Hesab yarat
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      search: null,
      showInput: false,
      fullName: null
    }
  },
  computed: {
    ...mapState({
      accessToken: state => state.auth.accessToken
    }),
    urlName() {
      return this.$route.name
    },
  },
  methods: {
    hideSearchBar() {
      if (this.showInput) this.showInput = false
    },
    searchUsersByName() {
      // this.toggleSearchInput()
      this.showInput = false
      this.$router.push({ name: 'employees', query: { fullname: this.fullName, page: 1 } })
        .then(res => {
          this.fullName = null
        })
        .catch(err => { })
    },
    toggleSearchInput() {
      this.showInput = !this.showInput

      if (this.showInput) {
        setTimeout(() => {
          this.$refs.searchInput?.focus()
        }, 100);
      }
    },
    routeTo(pathName) {
      this.$router.push({ name: pathName })
        .catch((error) => {
        });
    }
  }
}
</script>

<style scoped>
.nav-link {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.navbar-light .navbar-nav .nav-link.active {
  color: #ED1969;
  font-weight: 500;

}

.btn:focus {
  box-shadow: none;
}

.search-bar input {
  border-radius: 6.25rem;
  border: 1px solid #797979;
  height: 3.25rem;
  /* padding: 0.4375rem 1.25rem; */
  padding: 0 2.5rem;
  width: 36.25rem;
}

.search-bar input:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  /* box-shadow: 0 0 0 .25rem rgba(236, 24, 105, 0.25); */
  outline: 0;
}

.search-bar img {
  left: 1.25rem;
  top: 1.1rem;
}

@media screen and (max-width: 992px) {
  .search-bar input{
    width: 310px;
  }
}

@media screen and (max-width: 576px) {
  .search-icon {
    margin-left: auto;
    margin-right: auto;
  }

  .search-bar input {
    width: 100%;
    margin-top: 2rem;
  }

  .search-bar img {
    left: 1.25rem;
    top: 3.1rem;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .search-icon {
    margin-right: 1rem;
  }
}
</style>

<style>
.el-dropdown-menu.el-popper {
  border-radius: 0.375rem 0.375rem 0rem 0rem;
}

.el-dropdown-menu.el-popper li.el-dropdown-menu__item {
  color: var(--Neutral-black, var(--black, #111));
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 125%; */
  text-decoration: none;
  /* margin: 0 20px; */
}

/* .search-input {
  max-width: 36.25rem;
}

.search-input .el-input__inner {
  border-radius: 6.25rem;
  border: 1px solid #797979;
  height: 3.25rem;
  padding: 0.4375rem 1.25rem;
}

.search-input .el-input__inner:focus {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}
.el-input__prefix{
  top: 1rem !important;
  left: 1.25rem !important;
}

.el-input--prefix .el-input__inner {
  padding-left: 44px !important;
} */
</style>