<template>
  <div>
    <div class="row justify-content-between">
      <div class="col">
        <div class="v-title">
          VIP
        </div>
      </div>
      <div class="col-auto">
        <router-link to="#" class="v-link">Hamısına bax</router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col">
        <carousel-3d :perspective="0" space="400" controlsVisible width="288" height="304" count="3" display="3" border="0" disable3d :loop="false">
          <slide :index="0">
            <div class="card" style="width: 18rem;">
              <img src="@/assets/premium-services-img.png" class="card-img-top" alt="premium services image">
              <div class="card-body">
                <h5 class="card-title">Premium xidmətlər</h5>
              </div>
            </div>
          </slide>
          <slide :index="1">
            <div class="card" style="width: 18rem;">
              <img src="@/assets/private-consultations-img.png" class="card-img-top" alt="private consultations image">
              <div class="card-body">
                <h5 class="card-title">Özəl Konsultasiyalar</h5>
              </div>
            </div>
          </slide>
          <slide :index="2">
            <div class="card" style="width: 18rem;">
              <img src="@/assets/luxury-barber-img.png" class="card-img-top" alt="luxury barber image">
              <div class="card-body">
                <h5 class="card-title">Luxury Saç Ustaları</h5>
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  components: {
    Carousel3d,
    Slide
  }
}
</script>

<style scoped>
  .v-title {
  color: var(--Neutral-black, var(--black, #111));
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25rem;
  /* 128.571% */
  margin-bottom: .75rem;
}

.v-link {
  color: var(--Primary-Pink-700, #ED1969);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 144.444% */
  text-decoration: none;
}

.card {
  border: none;
}

.card-title {
  overflow: hidden;
  color: var(--Neutral-Black-100, #282828);
  text-overflow: ellipsis;

  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.875rem;
}

.card-text {
  color: #797979;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}
</style>

<style>

.carousel-3d-controls a.prev {
  left: 0;
  color: var(--Primary-Pink-700, #ED1969);
}

.carousel-3d-controls a.next {
  right: 0;
  color: var(--Primary-Pink-700, #ED1969);
}
</style>